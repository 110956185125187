@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  padding: 30px 70px;
  color: rgb(0, 124, 208);
}

.navbar-logo {
  font-size: 2.8em;
  font-weight: bold;
}

.navbar-links {
  display: flex;
}

.nav-link {
  font-size: 1.2em;
  font-family: 'Poppins', sans-serif;;
  padding: 20px 30px;
  color:white;
  text-decoration: none;
  transition: 0.9s ease;
}

.nav-link:hover {
    color: rgb(0, 124, 208); /* Change color to blue on hover */
  }
  


/* Other styles... */

.nav-link.active {
    color: rgb(0, 124, 208); /* Blue color for the active link */
  }
  
  
  /* Rest of your styles... */
  
.nav-link-special {
  background-color: rgb(0, 124, 208);
  border-radius: 100px;
  color: black;


}
.nav-link-special:hover {
    background-color: white;
    border-radius: 100px;
    color: black;

}
.nav-link-special.active {
    background-color: rgb(0, 124, 208);
    border-radius: 100px;
    color: black;
  
  }
  


.navbar-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
}


/* Rest of your styles ... */

.hamburger-icon {
    display: none; /* Initially hidden on desktop */
  }
  
  .bar {
    display: block; /* The bars are visible */
    width: 30px; /* Width of the bars */
    height: 3px; /* Height of the bars */
    margin: 3px auto; /* Space between the bars */
    transition: 0.7s;
    background-color: #fff; /* Color of the bars */
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .navbar {
      padding: 25px; /* Adjust padding for mobile view */
    }
  
    .navbar-logo {
      font-size: 1.5em; /* Adjust logo size for mobile view */
    }
    .nav-link-special{
        width: 50%;
        margin: 15px;
    

    }
    .navbar-links {
      display: none;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 75px; /* Adjust based on actual height of your navbar */
      left: 0;
      background-color: #000;
      z-index: 1; /* Ensure the menu is above other content */
    }
  
    .navbar-links.expanded {
      display: flex; /* Show the links when expanded */
    }
  
    .nav-link {
      padding: 15px 20px; /* Adjust padding for mobile view */
      font-size: 1em; /* Adjust font size for mobile view */
    }
  
    .navbar-toggle {
      display: flex; /* Show the hamburger icon */
      flex-direction: column;
      justify-content: space-around;
      width: 30px; /* Width of the toggle button */
      height: 20px; /* Height of the toggle button */
    }
  
    .hamburger-icon {
      display: flex; /* Show the hamburger icon */
      flex-direction: column;
      justify-content: space-between;
      height: 100%; /* Full height of the toggle button */
    }
  
    .navbar-toggle.expanded .bar:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
  
    .navbar-toggle.expanded .bar:nth-child(2) {
      opacity: 0;
    }
  
    .navbar-toggle.expanded .bar:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }
  