/* asdasdsadadsasd */

.valuation-output {
  background-color: #007cd0;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.valuation-amount {
  font-size: 80px;
  margin: 20px 0;
  font-family: "Helvetica", sans-serif;
  font-weight: bolder;
  color: #fff; /* Example green color */
}

.valuation-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: white;
  outline: none;
  margin: 10px 0;
}

.valuation-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: lightgrey;
  cursor: pointer;
  margin-top: -3px;
  margin-left: 15%;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-family: "Helvetica", sans-serif;
  font-weight: bolder;
}

.consent-checkbox {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.consent-checkbox input[type="checkbox"] {
  margin-right: 10px;
}
.submit-button {
  background-color: white;
  color: #007cd0;
  border: none;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding in width */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* RadioButtons.css */

.radio-button-label {
  display: block;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ddd; /* Light grey border */
  border-radius: 5px;
  background-color: #f2f2f2; /* White background */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.radio-button-label:hover {
  background-color: #007cd0; /* Lighter grey background on hover */
}

.radio-button-label.selected {
  border-color: #007cd0; /* Blue border for selected radio button */
  background-color: #e7f3ff; /* Light blue background for selected radio button */
}

.radio-button {
  opacity: 0; /* Hide the default radio button */
  position: absolute;
}

/* ValuationForm.css */
.published-form {
  max-width: 400px;
  margin: 0 auto;
  /* text-align: center; */
}

.published-form button {
  margin-bottom: 1rem;
}

.published-title {
  font-size: 2rem;
  color: #007cd0;
  margin-bottom: 1rem;
  margin-top: 60px;
}

.published-heading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.published-description {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 1.5rem;
  text-align: left;
}

.published-description-step7 {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 1.5rem;
  text-align: center;
}

.published-form input[type="text"] {
  width: 90%;
  padding: 20px;
  border: 1px solid #000000;
  border-radius: 3px;
  font-size: 15px;
}

.published-form .error-message {
  text-align: left;
  color: red;
  font-size: 0.875rem;
  width: 100%;
}

.published-form .next-btn {
  display: flex; /* Enables Flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  /* padding: 20px 180px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 40px;
  transition: opacity 0.3s ease;
  font-weight: bold;
  font-size: 1rem;
  background-color: #007cd0; /* Blue background */
  color: white;
  width: 25rem; /* Fixed width */
  height: 60px; /* Fixed height */
}

.published-form .next-btn:hover {
  /* background-color: #000000; 
    color: rgb(255, 255, 255); */
  opacity: 0.8;
}

.calc-btn {
  padding: 20px 129px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 40px;
  transition: opacity 0.3s ease;
  font-weight: bold;
  font-size: 1rem;
  background-color: #007cd0; /* Blue background */
  color: white;
  display: flex; /* Enables Flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  width: 25rem; /* Fixed width */
  height: 60px; /* Fixed height */
}

@media (max-width: 768px) {
  .published-form input[type="text"] {
    width: 85%;
    padding: 15px;
    border: 1px solid #000000;
    border-radius: 3px;
    font-size: 15px;
  }
}

.contact-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.number-input {
  width: 85%;
  padding: 20px;
  border: 1px solid #000000;
  border-radius: 3px;
  font-size: 15px;
}

.email-field {
  font-size: 15px;
  width: 90%;
  height: 17.6px;
  padding: 20px;
  text-decoration-color: #ccc;
  margin: 10px 0;
  border: 1px solid #000000;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .published-form .next-btn {
    width: 299px;
    padding-right: 100px;
    padding-left: 100px;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 40px;

    transition: opacity 0.3s ease;
    font-weight: bold;
    font-size: 1rem;
    background-color: #007cd0; /* Blue background */
    color: white;
    
  }

  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 1px;
    height: 1px;
    animation: spin 1s linear infinite;
    margin: auto;
  
   
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .email-field {
    width: 82%;

    height: 10px;
    padding: 20px;
    text-decoration-color: #ccc;
    margin: 10px 0;
    border: 1px solid #000000;
    border-radius: 3px;
  }

  .published-title {
    font-size: 2rem;
    color: #007cd0;
    margin-bottom: 1rem;
    margin-top: 60px;
  }

  .contact-input {
    /* width: 100%; */
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .calc-btn {
    width: 299px;
    padding-right: 100px;
    padding-left: 100px;
    /* padding: 20px 100px; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 40px;
    transition: opacity 0.3s ease;
    font-weight: bold;
    font-size: 1rem;
    background-color: #007cd0; /* Blue background */
    color: white;
  }
}

.submit-button:disabled {
  background-color: #cccccc; /* Disabled background color */
  color: #666666; /* Disabled text color */
  cursor: not-allowed;
  opacity: 0.8; /* Make the button appear faded */
  /* You can also add a blur effect if you prefer */
  filter: blur(0.5px);
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
  margin: auto;

 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.scraped-data-section {
  margin-top: 5%;
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  background-color: #fff; /* White background */
  color: #000; /* Black text color */
  font-family: Arial, sans-serif; /* Default font */
  padding: 10px; /* Space inside the section */
  align-items: center; /* Center children horizontally */
  border-radius: 20px;
}

.top-container {
  display: flex;
  width: 100%; /* Take full width to align items properly */
  justify-content: flex-start; /* Align items to the start */
  align-items: center; /* Center items vertically */
  margin-bottom: 10px; /* Space between top container and extra items */
}

.image-container {
  margin-right: 20px; /* Space between the image and the text */
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.app-logo {
  width: 80px; /* Width of the logo */
  height: 80px; /* Height of the logo */
  border-radius: 20%;
}

.title-container {
  text-align: left; /* Align text to the left */
}

.app-title {
  font-size: 1.5em; /* Size of the app title */
  margin: 0;
  font-weight: bold; /* Make the title bold */
}

.app-subtitle {
  font-size: 1em; /* Size of the app subtitle */
  margin: 0;
}

.extra {
  display: flex;
  justify-content: space-between; /* Distribute space evenly between items */
  width: 90%; /* Full width to align items properly */
  margin-top: 5%;
}

.extra-item {
  text-align: center; /* Center the text within each item */
}

.extra-item strong {
  display: block; /* Block display to separate from the span */
  font-size: 1.4em; /* Size of the strong element */
  margin-bottom: 5px;
}

.extra-item span {
  font-size: 0.9em; /* Size of the span element */
}

.disclaimer-container {
 
  color: #ffffff; /* White text color */
  padding: 5px; 
  border-radius: 10px; /* Rounded corners (if desired) */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; /* Font family */
  font-size: 14px;
}

.disclaimer-container h6 {
  display: inline; /* Make the heading inline */
  margin-right: 10px; /* Space after the heading */
  font-size: 1.2em; /* Heading size */
}

.disclaimer-container p {
  display: inline; /* Make the paragraph inline */
  font-size: 1em; /* Paragraph text size */
}
