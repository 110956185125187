

  /* asdasdsadadsasd */
  


/* ValuationForm.css */
.unpublished-form {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
   

    
  }

  .unpublished-form button {
    margin-bottom: 1rem;
  }
  
  
  .unpublished-title {
    
    font-size: 2.0rem;
    color: #007cd0;
    margin-bottom: 1rem;
    margin-top: 60px; 
    
  }
  
  .unpublished-calculate {
    padding: 20px 129px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 40px;
    transition: opacity 0.3s ease;
    font-weight: bold;
    font-size: 1rem;;
    background-color: #007cd0; /* Blue background */
    color: white;
    display: flex; /* Enables Flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    width: 25rem; /* Fixed width */
    height: 60px; /* Fixed height */
    
  }
  .unpublished-calculate :hover{
    opacity: 0.1;
  }
  
  .unpublished-calculate.loading::after {
    content: '';
    animation: typing 2s steps(4) infinite;
  }
  
  @keyframes typing {
    0%, 100% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
  }
  
  .unpublished-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;

  }
  
  .unpublished-description {
    font-size: 1rem;
    color: #000000;
    margin-bottom: 1.5rem;
    text-align: left;
    
  }
  

  

  .unpublished-form input[type="text"] {
    width: 90%;
    padding: 20px;
    border: 1px solid #000000;
    border-radius: 3px;
    font-size: 15px;
  }

  .unpublished-form .error-message {
    color: red;
    font-size: 0.875rem;
    width: 40%;
  }
  

  
  .unpublished-form .next-btn {
    display: flex; /* Enables Flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    /* padding: 20px 180px; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 40px;
    transition: opacity 0.3s ease;
    font-weight: bold;
    font-size: 1rem;;
    background-color: #007cd0; /* Blue background */
    color: white;
    width: 25rem; /* Fixed width */
    height: 60px; /* Fixed height */
  }
  
  .unpublished-form .next-btn:hover {
    /* background-color: #000000; 
    color: rgb(255, 255, 255); */
    opacity: 0.8;
  }
  

.contact-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.email-input{
  font-size: 15px;
  width: 90%;
  height: 17.600px;
  padding: 20px;
  text-decoration-color: #ccc;
  margin: 10px 0;
  border: 1px solid #000000;
  border-radius: 3px;
}

  @media (max-width: 768px) {

    .unpublished-form input[type="text"] {
        width: 85%;
        padding: 15px;
        border: 1px solid #000000;
        border-radius: 3px;
        font-size: 15px;
      }

    .unpublished-form .next-btn {
       width: 299px;
       padding-right: 100px;
       padding-left: 100px;
       text-align: center;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 40px;
          
          transition: opacity 0.3s ease;
          font-weight: bold;
          font-size: 1rem;;
          background-color: #007cd0; /* Blue background */
          color: white;
      }
      .unpublished-calculate {
        /* padding: 20px 100px; */
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 40px;
        transition: opacity 0.3s ease;
        font-weight: bold;
        font-size: 1rem;;
        background-color: #007cd0; /* Blue background */
        color: white;
        width: 299px;
        padding-right: 100px;
        padding-left: 100px;
        
      }

      .email-input{
        width: 82%;

        height: 10px;
        padding: 20px;
        text-decoration-color: #ccc;
        margin: 10px 0;
        border: 1px solid #000000;
        border-radius: 3px;
      }
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
