
/* ValuationForm.css */
.valuation-form {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;

  }
  
  .valuation-title {
    
    font-size: 2.0rem;
    color: #007cd0;
    margin-bottom: 1rem;
    margin-top: 60px; 
    
  }

  .valuation-form button {
    margin-bottom: 1rem;
  }
  
  .valuation-calculator-btn {
    font-size: 1.2rem;
    background-color: #007cd0; /* Green background */
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  

  
  .valuation-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;

  }
  
  .valuation-description {
    font-size: 1rem;
    color: #000000;
    margin-bottom: 1.5rem;
    text-align: left;
    
  }
  

  .button-group {
    display: flex;
    justify-content: left;
    gap: 1rem;
  }
  
  .app-status-btn {
    padding: 20px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    font-weight: bold;
    
  }

  .unpublished, .published {
    background-color: #007cd0; /* Blue background */
    color: white;
  }
  
  .unpublished:hover, .published:hover {
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    div.calculation{
      padding-top: 40px;
      padding-bottom: 40px;
      margin-left: 10%;
      margin-right: 10%;
    }

    .valuation-form {
      max-width: 300px;
      margin: 0 auto;
      text-align: center;
  
    }
    .valuation-description {
      width: fit-content;
      font-size: 1rem;
      color: #000000;
      margin-bottom: 1.5rem;
      text-align: left;
      
    }
    .valuation-heading {
      font-size: 1rem;
      font-weight: bold;
      color: #333;
      margin-bottom: 0.5rem;
  
    }

    .app-status-btn {
      /* padding: 20px 35px; */
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: opacity 0.3s ease;
      font-weight: bold;
      
    }
    .unpublished, .published {
      padding: auto;
      background-color: #007cd0; /* Blue background */
      color: white;
    }
    
    
    }
    .calculation{
      padding-top: 40px;
      padding-bottom: 40px;
      margin-left: 25%;
      margin-right: 25%;
    }

    .calculation-heading{
      font-size: 52px; /* Example size, adjust as needed */
       /* font-weight: bold; */
      color: #333; /* Dark grey text color */
      margin-bottom: 10px; /* Space below the heading */
      text-align: left; /* Center the heading text */
      font-family: Hellix, sans-serif;
    }
    .calculation-para{
      text-align: justify;
      font-size: 20px;
      font-weight: normal; /* Could be 'bold' if text is bold */
      font-family: "Nhaasgrotesktxpro 55rg", sans-serif;
    }
    .calculation-h3{
      font-weight: bold;
    }
    .calculation-h4{
      font-weight: bold;
    }
