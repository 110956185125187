/* ProgressBar.css */
.progress-bar-container {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin: 20px 0;
    text-align: center;
  }
  
  .progress-bar {
    height: 10px;
    background-color: #007cd0;
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
  }
  
  @media (max-width: 768px) {
    .progress-bar-container {
      width: 80%;
  margin-left: 30px;

 
    }

  }